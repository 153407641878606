import React, {Component} from 'react';
import {
    Modal,
    FormControl,
    Button, Col, Checkbox, Row
} from 'react-bootstrap';
import axios from "axios";

export default class InitialButtons extends Component {
    constructor() {
        super();
        this.state = {
            skipModalShow: false,
            exclude: false,
            isCalling: false
        };
        this.reason = '';
    }

    // componentDidMount() {
    //     let data= {
    //             cxToken: window.localStorage.getItem('token_3cx'),
    //             extension: window.localStorage.getItem("extension"),
    //             homeoffice: window.localStorage.getItem("homeoffice"),
    //             hangUpMethod: "EcoComponentDidMount"
    //         }
    //
    //     axios.post(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token')
    //         + "&action=HANGUP",data).then(
    //         res => {
    //         }
    //     ).catch(error => {
    //             console.log(error)
    //         }
    //     )
    // }

    skipHandler = () => {
        if (String(this.reason).length > 0) {
            this.skipModalHide();
            this.props.setData("status", 1);
            this.props.setData("note", this.reason);
            this.props.setData("exclude", this.state.exclude);
            this.setState({exclude: false});
            this.props.skip();
        }
    };

    nextHandler = (numberFrom) => {
        if (numberFrom !== "cloudtalk") {
            let data = {
                cxToken: window.localStorage.getItem('token_3cx'),
                extension: window.localStorage.getItem("extension"),
                numberFrom: numberFrom,
                numberTo: this.props.number,
                homeoffice: window.localStorage.getItem("homeoffice")
            }

            this.setState({isCalling: true});

            axios.post(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token')
                + "&action=CALL", data).then(
                res => {
                    this.props.next();
                }
            ).catch(error => {
                    console.log(error)
                }
            ).finally(() => {
                this.setState({isCalling: false});
            });
        } else {
            this.props.next();
        }

    };

    skipModalHide = () => {
        this.setState({skipModalShow: false});
    };

    skipModalShow = () => {
        this.setState({skipModalShow: true});
    };

    setReason = (event) => {
        this.reason = event.target.value;
    };

    toggleExclude = () => {
        this.setState({exclude: !this.state.exclude});
    }

    render() {
        return (
            <div>
                <div className="row main-btns">
                    <div className="col-md-6 col-xs-12">
                        <button type="button" className="btn btn-warning btn-xlg"
                                onClick={this.skipModalShow.bind(this)}>
                            <i className="icon-redo2 position-left"/>Preskoč
                        </button>
                        <Modal
                            show={this.state.skipModalShow}
                            onHide={this.skipModalHide}
                        >
                            <Modal.Header className="bg-primary">
                                <Modal.Title>Dôvod preskočenia</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md={12}>
                                        <FormControl
                                            id="reason"
                                            componentClass="textarea"
                                            placeholder="Zadajte dôvod preskočenia kontaktu (povinné)"
                                            onChange={this.setReason}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Checkbox label={"Vylúčiť z fronty"}
                                                  checked={Boolean(this.state.exclude) === true} position="right"
                                                  onChange={this.toggleExclude}>{"Vylúčiť z fronty"}</Checkbox>

                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.skipModalHide}>Zrušiť</Button>
                                <Button className="btn-primary" onClick={this.skipHandler.bind(this)}>Odoslať</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                        {/*<div className="col-md-3">*/}
                        {/*    <button type="button" className="btn btn-success btn-xlg" disabled={this.state.isCalling} onClick={this.nextHandler.bind(this, window.localStorage.getItem("number1"))}>*/}
                        {/*        {window.localStorage.getItem("number1")}*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-3">*/}
                        {/*    <button type="button" className="btn btn-primary btn-xlg" disabled={this.state.isCalling} onClick={this.nextHandler.bind(this, window.localStorage.getItem("number2"))}>*/}
                        {/*        {window.localStorage.getItem("number2")}*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-3">*/}
                        {/*    <button type="button" className="btn btn-primary btn-xlg" disabled={this.state.isCalling} onClick={this.nextHandler.bind(this, window.localStorage.getItem("number3"))}>*/}
                        {/*       {window.localStorage.getItem("number3")}*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    <div className="col-md-6">
                        <button type="button" className="btn btn-primary" disabled={this.state.isCalling}>
                            <a href={"tel:" + this.props.number.replace(/^0/, '+421')} onClick={this.nextHandler.bind(this, "cloudtalk")}>
                                <button type="button" className="btn btn-cloudtalk full-width btn-primary btn-xlg" disabled={this.state.isCalling}>
                                    Cloudtalk
                                </button>
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

