import React, {Component} from 'react';
import InitialButtons from "./Components/Workflow/InitialButtons";
import ResponseButtons from "./Components/Workflow/ResponseButtons";
import GDPRButtons from "./Components/Workflow/GDPRButtons";
import InterestButtons from "./Components/Workflow/InterestButtons";
import SecondCallButtons from "./Components/Workflow/SecondCallButtons";
import axios from "axios/index";
import AnswerButtons from "./Components/Workflow/AnswerButtons";
import SupportButtons from "./Components/Workflow/SupportButtons";
import OrderButtons from "./Components/Workflow/OrderButtons";

export default class Workflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttons: props.buttons,
            firstCall: props.firstCall ? props.firstCall : false,
            agreed: props.agreed ? props.agreed : false,
            hangModal: false,
            gifts: [],
            reasons: [],
            calendars: [],
            displays: [],
            products: [],
            hotspl: [],
            number: props.number,
            support: props.support === true ? props.support : props.support,
            isLoaded: props.isLoaded,
            frame: undefined,
            calendar: {}
        };
        this.groupCount = 4;
        this.nextGroup = this.nextGroup.bind(this);
        this.nextCall = this.nextCall.bind(this);
        this.split = this.split.bind(this);

        axios.get(window.localStorage.getItem('url') + '/CallcentrumQueueServlet?token=' + window.localStorage.getItem('token') + "&gifts=true").then(
            res => {
                let gifts = res.data.gifts;
                this.setState({gifts: gifts});
            }
        ).catch(error => {
                console.log(error);
            }
        );

        axios.get(window.localStorage.getItem('url') + '/CallcentrumQueueServlet?token=' + window.localStorage.getItem('token') + "&reasons=true").then(
            res => {
                let reasons = res.data.reasons;
                console.log(reasons);
                if (reasons === null || reasons === undefined || reasons.length === 0) {
                    reasons = [{id: 1}]
                }
                this.setState({reasons: reasons});
            }
        ).catch(error => {
                console.log(error);
            }
        );

        axios.get(window.localStorage.getItem('url') + '/CallcentrumQueueServlet?token=' + window.localStorage.getItem('token') + "&calendars=true").then(
            res => {
                let calendars = res.data.calendars;
                this.setState({calendars: calendars});
            }
        ).catch(error => {
                console.log(error);
            }
        );

        axios.get(window.localStorage.getItem('url') + '/CallcentrumQueueServlet?token=' + window.localStorage.getItem('token') + "&displays=true").then(
            res => {
                let displays = res.data.displays;
                this.setState({displays: displays});
            }
        ).catch(error => {
                console.log(error);
            }
        );

        axios.get(window.localStorage.getItem('url') + '/CallcentrumQueueServlet?token=' + window.localStorage.getItem('token') + "&products=true").then(
            res => {
                let products = res.data.lggs;
                this.setState({products: products});
            }
        ).catch(error => {
                console.log(error);
            }
        );

        axios.get(window.localStorage.getItem('url') + '/CallcentrumQueueServlet?token=' + window.localStorage.getItem('token') + "&hotspl=true").then(
            res => {
                let hotspl = res.data.hotspl;
                this.setState({hotspl: hotspl});
            }
        ).catch(error => {
                console.log(error);
            }
        );
    }

    promptHang = () => {
        this.setState({hangModal: true}, () => {

        });
    };
    closeHangPrompt = () => {
        this.setState({hangModal: false});
    };

    hangHandler = () => {
        this.nextCall()

        // let data= {
        //     cxToken: window.localStorage.getItem('token_3cx'),
        //     extension: window.localStorage.getItem("extension"),
        //     homeoffice: window.localStorage.getItem("homeoffice"),
        //     hangUpMethod: "EcoButtonWorkflow"
        // }
        // axios.post(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token')
        //     + "&action=HANGUP",data).then(
        //     res => {
        //         this.closeHangPrompt();
        //     }
        // ).catch(error => {
        //         console.log(error)
        //     }
        // );
    };

    componentWillReceiveProps(props) {
        this.setState({
            firstCall: props.firstCall,
            agreed: props.agreed,
            number: props.number,
            buttons: props.buttons,
            support: props.support,
            isLoaded: props.isLoaded,
            frame: props.frame,
            calendar: props.calendar
        });
    }

    nextGroup = () => {
        let groupIndex = (this.state.buttons + 1) % this.groupCount;
        this.props.toGroup(groupIndex);
    };

    nextGroupFromGdpr = () => {
        if (window.localStorage.getItem("aktiv") === "true") {
            this.props.toGroup(6);
        } else {
            this.nextGroup();
        }
    };

    nextCall = () => {
        this.props.nextCall();
    };

    split = () => {
        if (this.state.firstCall && !this.state.support) {
            if (this.state.agreed === true) {
                if (window.localStorage.getItem("aktiv") === "true") {
                    this.props.toGroup(6);
                } else {
                    this.props.toGroup(3);
                }
            } else {
                this.props.toGroup(2);
            }
        } else if (this.state.support) {
            this.props.toGroup(5);
        } else {
            this.props.toGroup(4);
        }
    };

    answerCall = () => {
        this.props.toGroup(2);
    };

    render() {
        let result;
        switch (this.state.buttons) {
            default:
            case 0:
                result = <InitialButtons skip={this.props.getNext} next={() => this.props.toGroup(1)}
                                         setData={this.props.setData}
                                         number={this.state.number}/>;
                break;

            case -1:
                result = <AnswerButtons hang={this.props.getNext} answer={this.answerCall.bind(this)}/>;
                break;

            case 1:
                result = <ResponseButtons hang={this.nextCall} respond={this.split} setData={this.props.setData}/>;
                break;

            case 2:
                result =
                    <GDPRButtons agree={this.nextGroupFromGdpr} disagree={this.nextCall} setData={this.props.setData}/>;
                break;

            case 3:
                result =
                    <InterestButtons next={() => this.props.toGroup(7)} setData={this.props.setData}
                                     reasons={this.state.reasons}
                                     gifts={this.state.gifts} displays={this.state.displays}
                                     calendars={this.state.calendars}
                    />;
                break;

            case 4:
                result =
                    <SecondCallButtons next={() => this.props.toGroup(7)} setData={this.props.setData}
                                       reasons={this.state.reasons} gifts={this.state.gifts}
                                       displays={this.state.displays}
                                       calendars={this.state.calendars} calendar={this.state.calendar}/>;
                break;

            case 5:
                result = <SupportButtons next={this.nextCall} setData={this.props.setData}/>;
                break;

            case 6:
                result = <OrderButtons next={() => this.props.toGroup(7)} setData={this.props.setData}
                                       products={this.state.products} displays={this.state.displays}
                                       calendars={this.state.calendars} gifts={this.state.gifts}
                                       hotspl={this.state.hotspl}/>;
                break;

            case 7:
                result = <React.Fragment>
                    <div className="row main-btns">
                        <div className="col-md-12">
                            <button type="button" className="btn btn-danger btn-xlg"
                                    onClick={this.hangHandler.bind(this)}>Ukončiť
                            </button>
                        </div>
                    </div>
                    {/*<Modal show={this.state.hangModal}*/}
                    {/*       onHide={this.closeHangPrompt.bind(this)}*/}
                    {/*       bsSize={"small"}>*/}
                    {/*    <Modal.Header className={"btn-warning"}>*/}
                    {/*        <Modal.Title>Prajete si zrušiť hovor?</Modal.Title>*/}
                    {/*    </Modal.Header>*/}
                    {/*    <Modal.Body>*/}
                    {/*        <Button className={"btn-warning"}*/}
                    {/*                onClick={this.hangHandler.bind(this)}>Áno</Button>*/}
                    {/*        <Button onClick={this.closeHangPrompt.bind(this)}>Nie</Button>*/}
                    {/*    </Modal.Body>*/}
                    {/*</Modal>*/}
                </React.Fragment>
                break;

        }

        if (this.state.isLoaded) {
            return (
                <div>
                    {result}
                </div>
            );
        } else {
            return (<div>&nbsp;</div>);
        }

    }

}

